import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PersonIcon from '@material-ui/icons/Person';

import './NameTag.less';

const NameTag = ({ setName, name, setServerName }) => {
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(true);
  };

  const closeIfOk = () => {
    if (name.length > 0) {
      setIsModal(false);
      setServerName(name);
    }
  };

  return (
    <div className="NameTag">
      <PersonIcon />
      <div>{name}</div>
      <Button
        variant="text"
        color="secondary"
        onClick={openModal}
        className="editButton"
      >
        <EditIcon />
      </Button>

      <Dialog
        open={isModal}
        onClose={closeIfOk}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <TextField
            autoFocus
            label="Pseudo"
            variant="outlined"
            value={name}
            onChange={e =>
              setName(
                e.target.value.substr(0, 1).toUpperCase() +
                  e.target.value.substr(1, e.target.value.length)
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeIfOk}
            color="primary"
            disabled={name.length === 0}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NameTag;
