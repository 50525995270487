/**
 * Fonction random retourne entre : [min,max[
 * --> JAMAIS = MAX ! et valeur pas dans notIn
 * --> Si impossible retourne NULL
 * @param {*} min
 * @param {*} max
 * @param {*} notIn
 */
export const rand = (min, max, notIn = []) => {
  // Populate an Array with all possibles values
  let range = [];
  for (let i = min; i < max; i++) {
    // On dégage les valeurs notIn
    if (notIn.indexOf(i) === -1) {
      range.push(i);
    }
  }

  if (range.length > 0) {
    const rangeMax = range.length;
    let r = Math.floor(Math.random() * rangeMax);
    return range[r];
  } else {
    return null;
  }
};
