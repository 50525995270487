import React, { useState } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { Button, Switch } from '@material-ui/core';

import { ReactComponent as Unlocked } from '../../SVG/unlocked.svg';
import { ReactComponent as Locked } from '../../SVG/locked.svg';
import BuzzerButton from '../Utils/BuzzerButton';
import './Dashboard.less';
import BuzzList from '../Utils/BuzzList';

const Dashboard = ({ socket, room, setIsConfig, setRoom }) => {
  const [isLocked, setIsLocked] = useState(room.isLocked || false);

  const clear = () => {
    socket.emit('room.clear');
  };

  const toggleLock = () => {
    setIsLocked(!isLocked);
    socket.emit('room.lock', !isLocked);
  };

  const changeScore = (id, cb) => {
    const nextPlayerList = [...room.playerList];
    const player = nextPlayerList.filter(p => p.id === id);
    if (player) {
      player[0].points += cb;
      setRoom({ ...room, playerList: nextPlayerList });
      socket.emit('room.updatePlayers', nextPlayerList);
    }
  };

  const cooldown = id => {
    socket.emit('room.cooldown', id);
  };

  return (
    <div className="dashboard">
      <div className="listWrapperDashBoard  custom-scrollbar">
        <BuzzList
          buzzList={room.buzzList}
          isHost
          playerList={room.playerList}
          changeScore={changeScore}
          cooldown={cooldown}
        />
      </div>
      <div className="lockAndReset">
        <div className="lockDiv">
          <Switch checked={isLocked} onChange={toggleLock} />
          {isLocked ? (
            <Locked className="lockIcon" onClick={toggleLock} />
          ) : (
            <Unlocked className="lockIcon" onClick={toggleLock} />
          )}
        </div>
        <Button
          variant="contained"
          color="secondary"
          onClick={clear}
          disabled={room.buzzList.length === 0}
        >
          Reset
        </Button>
      </div>
      <div className="configWrapper">
        <BuzzerButton
          content={<SettingsIcon className="iconSettings" />}
          size={4}
          color="#7d4f14"
          onBuzz={() => setIsConfig(true)}
        />
      </div>
    </div>
  );
};

export default Dashboard;
