import React from 'react';
import { toast } from 'react-toastify';
import './CreateRoom.less';
import BuzzerButton from '../Utils/BuzzerButton';

const CreateRoom = ({ socket, setRoom, setIndex, noSleep }) => {
  const createRoom = () => {
    //Active NoSleep
    if (!noSleep.enabled) {
      console.log('Wakelock On');
      noSleep.enable();
    }

    if (!socket) {
      toast.error('Problème de connection au serveur (No socket)');
      return;
    }
    socket.emit('room.create', r => {
      if (!r.isOk) {
        toast.error('ERREUR : ', r.error);
      } else {
        setRoom(r.room);
        setIndex(1);
      }
    });
  };

  return (
    <div className="createRoom">
      <h1>Buzz Me !</h1>
      <div className="explain">
        Grâce à Buzz Me vous pouvez créer un système de buzzers entre amis pour
        tous vos défis / jeux et questions en folie. Il suffit de créer une
        salle et de partager le lien à tous les participants !
      </div>
      {socket && <BuzzerButton content="Créer une salle" onBuzz={createRoom} />}
    </div>
  );
};

export default CreateRoom;
