import JoinSound from './Sounds/join.mp3';
import BuzzerSound from './Sounds/buzzer.mp3';
import AMoiSound from './Sounds/aMoi.mp3';
import ChampionSound from './Sounds/champion.mp3';
import DingDongSound from './Sounds/dingdong.mp3';
import DringSound from './Sounds/dring.mp3';
import DropletSound from './Sounds/droplet.mp3';

export const isProduction = process.env.NODE_ENV === 'production';
export const PORT = 7775;
export const LOCATION = isProduction
  ? `https://buzzme.idontcare.fr`
  : `http://192.168.1.32`;
export const URL_SERVER = `${LOCATION}:${PORT}`;
export const URL_LOCAL = isProduction ? LOCATION : `192.168.1.31:3000`;

export const SOUNDS = {
  join: JoinSound,
  buzzer: BuzzerSound,
  aMoi: AMoiSound,
  dingDong: DingDongSound,
  dring: DringSound,
  champion: ChampionSound,
  droplet: DropletSound
};

export const BUZZER_SOUNDS = [
  { id: 'buzzer', name: 'Buzzer' },
  { id: 'dingDong', name: 'Ding Dong' },
  { id: 'dring', name: 'Driiing' },
  { id: 'champion', name: 'Champion' },
  { id: 'droplet', name: 'Goutte' },
  { id: 'aMoi', name: 'À moi ?' }
];

export const DEFAULT_RING_COLOR = '#2b1800';
export const ACTIVE_RING_COLOR = '#ffe7c9';
export const FIRST_RING_COLOR = '#67ef58';

export const ALL_COLORS = [
  '#ff0000',
  '#00ff00',
  '#0000ff',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b'
];
