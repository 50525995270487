import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import io from 'socket.io-client';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import NoSleep from 'nosleep.js';

import { isProduction, URL_SERVER, SOUNDS } from './Constantes';
import Buzzer from './Components/Users/Buzzer';
import StepName from './Components/Users/StepName';
import CreateRoom from './Components/Admin/CreateRoom';
import Admin from './Components/Admin/Admin';
import './App.less';

var noSleep = new NoSleep();

const App = () => {
  const [socket, setSocket] = useState(null);
  const [room, setRoom] = useState(null);

  const initName = localStorage.getItem('name') || '';
  const [name, setName] = useState(initName);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const roomId = urlParams.get('r');
  let indexInit = 0;
  if (roomId !== null && initName.length > 0) {
    indexInit = 1;
  }
  const [index, setIndex] = useState(indexInit);

  const saveName = nextName => {
    setName(nextName);
    localStorage.setItem('name', nextName);
  };

  const playSound = soundId => {
    const audio = new Audio(SOUNDS[soundId]);
    audio.play();
  };

  const updateRoom = newRoom => {
    // console.log(newRoom);
    setRoom(newRoom);
  };

  const playerLeft = name => {
    toast.error(`${name} est parti`);
  };

  const onLeavePage = e => {
    e.returnValue = 'Prevent unwanted reload';
    return 'Prevent unwanted reload';
  };

  useEffect(() => {
    // Empêche le menu contextuel sur mobile au longPress
    // and btw le click droit aussi donc que en prod
    if (isProduction) {
      window.oncontextmenu = function(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
      window.addEventListener('beforeunload', onLeavePage);
    }

    const socketInit = io.connect(URL_SERVER, { secure: isProduction });

    // socket events from server
    socketInit.on('playSound', playSound);
    socketInit.on('room.update', updateRoom);
    socketInit.on('player.left', playerLeft);

    socketInit.on('disconnect', reason => {
      console.log('MAYDAY MAYDAY SERVER LOST : ' + reason);
    });

    setSocket(socketInit);

    // Connecte le joueur à la room passée en paramètre
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const roomId = urlParams.get('r');
    if (roomId !== null) {
      //Supprime le paramètre de la room de l'URL :
      window.history.pushState('page2', 'Title', window.location.origin);

      //Connecte la personne dans la room :
      socketInit.emit('room.join', roomId, name, retour => {
        if (!retour.isOk) {
          toast.error(retour.error);
          // setIndex(0);
        }
      });
    }
  }, []);

  const setServerName = newName => {
    if (room && room.playerList) {
      const newPL = [...room.playerList];
      const player = newPL.filter(p => p.id === socket.id);
      if (player.length) {
        player[0].name = newName;
        socket.emit('room.updatePlayers', newPL);
      }
    }
  };

  return (
    <>
      <SwipeableViews id="swipeViewWrapper" resistance index={index} disabled>
        <div className="fullScreen">
          {roomId !== null || room !== null ? (
            <StepName
              setName={saveName}
              name={name}
              setIndex={setIndex}
              setServerName={setServerName}
            />
          ) : (
            <CreateRoom
              noSleep={noSleep}
              setRoom={setRoom}
              socket={socket}
              setIndex={setIndex}
            />
          )}
        </div>
        <div className="fullScreen">
          {socket && index === 1 && (
            <>
              {room && room.host === socket.id ? (
                <Admin socket={socket} room={room} setRoom={setRoom} />
              ) : (
                <Buzzer
                  noSleep={noSleep}
                  setName={saveName}
                  setServerName={setServerName}
                  socket={socket}
                  room={room}
                  name={name}
                  setIndex={setIndex}
                />
              )}
            </>
          )}
        </div>
      </SwipeableViews>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />
    </>
  );
};

export default App;
