import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './StepName.less';

const StepName = ({ setName, name, setIndex, setServerName }) => {
  const next = e => {
    if (e) {
      e.preventDefault();
    }
    setIndex(1);
    setServerName(name);
  };
  return (
    <div className="stepName">
      <div className="title">Buzz Me</div>
      <form className="form" noValidate autoComplete="off" onSubmit={next}>
        <TextField
          label="Pseudo"
          variant="outlined"
          value={name}
          onChange={e =>
            setName(
              e.target.value.substr(0, 1).toUpperCase() +
                e.target.value.substr(1, e.target.value.length)
            )
          }
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={next}
          disabled={name.length === 0}
        >
          <ArrowForwardIcon />
        </Button>
      </form>
    </div>
  );
};

export default StepName;
