import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import { IconButton } from '@material-ui/core';

import { ReactComponent as First } from '../../SVG/first.svg';
import { ReactComponent as Second } from '../../SVG/second.svg';
import { ReactComponent as Third } from '../../SVG/third.svg';
import { ReactComponent as AddDiamond } from '../../SVG/addDiamond.svg';
import { ReactComponent as MinusDiamond } from '../../SVG/minusDiamond.svg';
import { ReactComponent as Diamond } from '../../SVG/diamond.svg';
import { ReactComponent as Chrono } from '../../SVG/chrono.svg';
import './BuzzList.less';

const BuzzList = ({
  buzzList,
  isHost,
  playerList,
  hideScore,
  changeScore = () => {},
  cooldown = () => {}
}) => {
  const getIcon = index => {
    const cla = isHost ? 'iconRank' : 'iconRankDense';
    switch (index) {
      case 0:
        return <First className={cla} />;
      case 1:
        return <Second className={cla} />;
      case 2:
        return <Third className={cla} />;
      default:
        return <PersonIcon />;
    }
  };

  const getText = id => {
    const player = playerList.filter(p => p.id === id);
    if (!player || !player[0]) {
      return '<<< Player left >>>';
    }
    return (
      <div className="line">
        <div className="infos">
          <div className="playerName">{player[0].name}</div>
          {(isHost || !hideScore) && (
            <div className="score">
              <div className="playerPoints"> ➔ {player[0].points}</div>
              <Diamond className="MuiSvgIcon-root" />
            </div>
          )}
        </div>
        {isHost && (
          <div className="actions">
            <IconButton edge="end" onClick={() => changeScore(id, 1)}>
              <AddDiamond className="MuiSvgIcon-root" />
            </IconButton>
            <IconButton edge="end" onClick={() => changeScore(id, -1)}>
              <MinusDiamond className="MuiSvgIcon-root" />
            </IconButton>
            <IconButton edge="end" onClick={() => cooldown(id)}>
              <Chrono className="MuiSvgIcon-root" />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  return (
    <List className="buzzList" dense={!isHost}>
      {buzzList.map((buzz, index) => (
        <React.Fragment key={index}>
          <ListItem>
            <ListItemIcon>{getIcon(index)}</ListItemIcon>
            <ListItemText primary={getText(buzz.id)} />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default BuzzList;
