import React, { useEffect, useState } from 'react';

import ColorPicker from '../Admin/ColorPicker';
import BuzzerButton from '../Utils/BuzzerButton';
import { ReactComponent as Locked } from '../../SVG/locked.svg';
import { ReactComponent as Diamond } from '../../SVG/diamond.svg';
import { ReactComponent as Chrono } from '../../SVG/chrono.svg';
import BuzzList from '../Utils/BuzzList';
import NameTag from './NameTag';
import './Buzzer.less';
import {
  DEFAULT_RING_COLOR,
  ACTIVE_RING_COLOR,
  SOUNDS,
  FIRST_RING_COLOR
} from '../../Constantes';

let t;

const Buzzer = ({ socket, name, setName, room, noSleep, setServerName }) => {
  const [ringColor, setRingColor] = useState(DEFAULT_RING_COLOR);

  const getConf = id => {
    const global = room && room.config;
    if (!global) {
      return null;
    }
    if (global.isCustom) {
      const customTab = global.custom && global.custom.filter(p => p.id === id);
      if (customTab.length > 0) {
        // Prends toutes les valeurs globales puis écrase avec d'éventuels custom
        return { ...global, ...customTab[0] };
      } else {
        return global;
      }
    }
    return global;
  };

  const [color, setColor] = useState({ hex: '#FF0000' });
  const [customConf, setCustomConf] = useState(null);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [me, setMe] = useState({});

  // Clean le timer
  useEffect(() => {
    return () => {
      clearTimeout(t);
      t = null;
    };
  }, []);

  // Set l'objet 'me'
  useEffect(() => {
    if (room && room.playerList) {
      const player = room.playerList.filter(p => p.id === socket.id);
      if (player) {
        setMe(player[0]);
      }
    }
  }, [room && room.playerList]);

  // Sélectionne la bonne conf (global ou custom) et set le flag de selction de couleur local
  useEffect(() => {
    if (room && room.config) {
      const co = getConf(socket.id);
      setCustomConf(co);
      if (!co.isColorChoice) {
        setColor(co.color);
      }
    }
  }, [room && room.config]);

  //Gère le blink du ring
  const toggleRingColorTick = () => {
    if (t) {
      setRingColor(oldValue => {
        return oldValue === ACTIVE_RING_COLOR || oldValue === ACTIVE_RING_COLOR
          ? FIRST_RING_COLOR
          : ACTIVE_RING_COLOR;
      });
      clearTimeout(t);
      t = setTimeout(() => {
        toggleRingColorTick();
      }, 500);
    }
  };

  // Gère la couleur du contour du bouton :
  // Si déjà buzzé : blanc
  // Si déjà buzzé & premier : clignote vert / blanc
  useEffect(() => {
    if (room && room.buzzList) {
      const isInList = room.buzzList.filter(p => p.id === socket.id).length > 0;
      if (isInList) {
        const isFirst = room.buzzList.findIndex(p => p.id === socket.id) === 0;
        if (isFirst) {
          t = true;
          toggleRingColorTick();
        } else {
          clearTimeout(t);
          t = null;
          setRingColor(ACTIVE_RING_COLOR);
        }
      } else {
        clearTimeout(t);
        t = null;
        setRingColor(DEFAULT_RING_COLOR);
      }
    }
  }, [room && room.buzzList]);

  const buzz = () => {
    socket.emit('room.buzz');

    //Active NoSleep la première fois que le buzzer est activé
    if (!noSleep.enabled) {
      console.log('Wakelock On');
      noSleep.enable();
    }

    // Joue le son du buzzer ssi il n'est pas déjà en train d'être joué
    if (!isSoundOn) {
      setIsSoundOn(true);
      const audio = new Audio(SOUNDS[room.config.buzzerSound]);
      audio.play();
      audio.onended = () => {
        setIsSoundOn(false);
      };
    }
  };

  const renderBuzzerContent = () => {
    if (room.isLocked) {
      return <Locked className="lockIcon" />;
    }
    if (me.isCooldown) {
      return <Chrono className="lockIcon" />;
    }
    return customConf.buzzerText;
  };

  // Empêche des "NPE" si on est pas encore co à la room
  if (!customConf || !room) {
    return null;
  }

  const globalConf = room.config || {};
  return (
    <div className="buzzerPage">
      <div className="topBar">
        <div>
          {customConf.isColorChoice && (
            <ColorPicker color={color} setColor={setColor} />
          )}
        </div>
        <div>
          <NameTag
            setName={setName}
            setServerName={setServerName}
            name={name}
          />
          <div className="diamondPoints">
            {me.points}
            <Diamond className="diamondIcon" />
          </div>
        </div>
      </div>
      {globalConf.seeBuzzList && (
        <div className="listWrapperBuzzer custom-scrollbar">
          <BuzzList
            buzzList={room.buzzList}
            playerList={room.playerList}
            hideScore={globalConf.hideScore}
          />
        </div>
      )}
      <div className="title">{globalConf.title}</div>
      <div className="wrapperBuzzer">
        <BuzzerButton
          content={renderBuzzerContent()}
          delay={0}
          color={color.hex}
          onBuzz={buzz}
          disabled={room.isLocked || me.isCooldown}
          ringColor={ringColor}
        />
      </div>
    </div>
  );
};

export default Buzzer;
