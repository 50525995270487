import {
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Switch from '@material-ui/core/Switch';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';

import { ReactComponent as QrCode } from '../../SVG/QrCode.svg';
import { ReactComponent as Copy } from '../../SVG/copy.svg';
import { ReactComponent as Share } from '../../SVG/share.svg';
import { ReactComponent as Random } from '../../SVG/random.svg';
import { ReactComponent as Import } from '../../SVG/import.svg';
import { ReactComponent as Bin } from '../../SVG/bin.svg';
import { ReactComponent as Play } from '../../SVG/play.svg';
import { ALL_COLORS, BUZZER_SOUNDS, SOUNDS, URL_LOCAL } from '../../Constantes';
import InputWithClear from '../Utils/InputWithClear';
import BuzzerButton from '../Utils/BuzzerButton';
import ColorPicker from './ColorPicker';
import './Config.less';
import { rand } from '../Utils/Utils';
import QRCode from 'react-qr-code';

const Config = ({ socket, room, setIsConfig }) => {
  // just a flag
  const [isSoundOn, setIsSoundOn] = useState(false);

  // Conf
  const [isCustom, setIsCustom] = useState(room.config.isCustom || false);
  const [customConf, setCustomConf] = useState(room.config.custom || []);
  const [isExpanded, setIsExpanded] = useState(room.config.isExpanded || false);
  const [title, setTitle] = useState(room.config.title || '');
  const [buzzerText, setBuzzerText] = useState(room.config.buzzerText || '');
  const [color, setColor] = useState(room.config.color || { hex: '' });
  const [isColorChoice, setIsColorChoice] = useState(
    room.config.isColorChoice || false
  );
  const [nbBuzz, setNbBuzz] = useState(room.config.nbBuzz || 1);
  const [buzzerSound, setBuzzerSound] = useState(
    room.config.buzzerSound || 'buzzer'
  );
  const [seeBuzzList, setSeeBuzzList] = useState(
    room.config.seeBuzzList || true
  );
  const [hideScore, setHideScore] = useState(room.config.hideScore || false);
  const [onlyFirst, setOnlyFirst] = useState(room.config.onlyFirst || false);
  const [freezeTime, setFreezeTime] = useState(room.config.freezeTime || 5);

  // Modal QR
  const [isModalOpen, setIsModalOpen] = useState(false);

  // getter par id
  const getCustomConf = id => {
    const c = customConf.filter(p => p.id === id);
    return c.length > 0 ? c[0] : null;
  };

  // Pour chaque nouveau joueur, ajoute un objet de conf vide ( que l'id )
  useEffect(() => {
    const newPlayerConf = room.playerList
      .filter(p => getCustomConf(p.id) === null)
      .map(p => ({ id: p.id }));
    setCustomConf([...customConf, ...newPlayerConf]);
  }, [room.playerList]);

  // Envoie la nouvelle conf au serveur ( répercutée à tous les joueurs )
  const validate = () => {
    setIsConfig(false);
    const newConf = {
      title,
      buzzerText,
      color,
      isCustom,
      custom: customConf,
      isExpanded,
      isColorChoice,
      nbBuzz,
      seeBuzzList,
      buzzerSound,
      hideScore,
      onlyFirst,
      freezeTime
    };
    socket.emit('config.new', newConf);
  };

  const selectAndCopyMe = e => {
    e.target.scrollLeft = e.target.scrollWidth;
    e.target.select();
    copyLink();
  };

  const selectMe = e => {
    e.target.scrollLeft = e.target.scrollWidth;
    e.target.select();
  };

  const shareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Envoyez ce lien aux participants`,
          url: window.location.href
        })
        .then(() => {
          toast.success('Lien partagé avec succès');
        })
        .catch(e => toast.error("Le lien n'a pas été envoyé !"));
    }
  };

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
      toast.success('Lien copié dans le presse-papier', {
        toastId: 'copySuccesfull'
      });
    } else {
      toast.error("Impossible d'accéder au presse-papier", {
        toastId: 'copyUnsuccesfull'
      });
    }
  };

  const randomColor = () => {
    return { hex: ALL_COLORS[rand(0, ALL_COLORS.length)] };
  };

  const renderGlobalConf = () => {
    return (
      <>
        <InputWithClear
          label={'Texte des buzzers'}
          value={buzzerText}
          setValue={setBuzzerText}
          placeholder="Buzz me"
        />
        {!isColorChoice && (
          <div className="globalColor">
            <div>Couleur des buzzers</div>
            <IconButton onClick={() => setColor(randomColor())}>
              <Random className="icon effect noSelect" />
            </IconButton>
            <ColorPicker setColor={setColor} color={color} />
          </div>
        )}
      </>
    );
  };

  // Set toutes les couleurs des buzzers des joueurs au hasard
  const randomizeAll = () => {
    let newConf = [...customConf];
    newConf = newConf.map(c => ({ ...c, color: randomColor() }));
    setCustomConf(newConf);
  };

  // Copie la conf global dans chaque objet custom
  const importFromGlobal = () => {
    let newConf = [...customConf];
    newConf = newConf.map(c => ({
      ...c,
      color: color,
      buzzerText: buzzerText
    }));
    setCustomConf(newConf);
  };

  // RaZ des objets custom
  const deleteCustom = () => {
    let newConf = [...customConf];
    newConf = newConf.map(c => {
      delete c.buzzerText;
      delete c.color;
      return c;
    });
    setCustomConf(newConf);
  };

  // MaJ de propriété souhaité de conf custom
  const setCustomConfProperty = (propertyName, value, id) => {
    const newConf = [...customConf];
    const conf = getCustomConf(id);
    if (conf) {
      conf[propertyName] = value;
      setCustomConf(newConf);
    }
  };

  const renderCustomConf = () => {
    return room.playerList.length > 0 ? (
      <div className="customList custom-scrollbar">
        <div className="customHeader">
          <IconButton onClick={deleteCustom}>
            <Bin className="icon effect noSelect" />
          </IconButton>
          <IconButton onClick={importFromGlobal}>
            <Import className="icon effect noSelect" />
          </IconButton>
          {!isColorChoice && (
            <IconButton onClick={randomizeAll}>
              <Random className="icon effect noSelect" />
            </IconButton>
          )}
        </div>
        {room.playerList.map((p, index) => (
          <React.Fragment key={index}>
            <div className="playerConfLine">
              <div className="nameConf">{p.name}</div>
              <InputWithClear
                label={'Texte'}
                id={index}
                value={(getCustomConf(p.id) || {}).buzzerText || ''}
                setValue={val => setCustomConfProperty('buzzerText', val, p.id)}
                clearValue={() => setCustomConfProperty('buzzerText', '', p.id)}
                placeholder="BUZZ"
                className={'textBuzzerInput'}
              />
              {!isColorChoice && (
                <ColorPicker
                  setColor={val => setCustomConfProperty('color', val, p.id)}
                  color={(getCustomConf(p.id) || {}).color || color}
                />
              )}
            </div>
            <Divider />
          </React.Fragment>
        ))}
      </div>
    ) : (
      <div>
        <div className="waiting">En attente de participants</div>
        <LinearProgress />
      </div>
    );
  };

  const handleNbBuzz = e => {
    const nb = parseInt(e.target.value);
    setNbBuzz(!nb ? 1 : nb);
  };

  const handleFreezeTime = e => {
    const nb = parseInt(e.target.value);
    setFreezeTime(!nb ? 1 : nb);
  };

  // Joue le son du buzzer ssi il n'est pas déjà en train d'être joué
  const playSound = soundId => {
    if (!isSoundOn) {
      setIsSoundOn(true);
      const audio = new Audio(SOUNDS[soundId]);
      audio.play();
      audio.onended = () => {
        setIsSoundOn(false);
      };
    }
  };

  const handleChangeSound = e => {
    const newSound = e.target.value;
    playSound(newSound);
    setBuzzerSound(newSound);
  };

  const url = `${URL_LOCAL}/?r=${room.id}`;
  return (
    <>
      <div className="urlShare">
        <TextField
          className="urlInput"
          label="URL à partager"
          value={url}
          onFocus={selectAndCopyMe}
        />
        <IconButton onClick={() => setIsModalOpen(true)}>
          <QrCode className="icon noSelect" color="white" />
        </IconButton>
        <Dialog
          className="qrModal"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <DialogContent>
            <div className="qrWrapper">
              <QRCode value={url} className="qrCode" />
            </div>
          </DialogContent>
        </Dialog>

        <IconButton onClick={copyLink}>
          <Copy className="icon noSelect" />
        </IconButton>
        {navigator.share && (
          <IconButton onClick={shareLink}>
            <Share className="icon noSelect" />
          </IconButton>
        )}
      </div>
      <InputWithClear
        className="setting"
        label={'Titre de la page'}
        value={title}
        setValue={setTitle}
        placeholder="Question pour un champion"
      />
      <FormControlLabel
        className="setting"
        control={
          <Switch
            checked={isColorChoice}
            color="primary"
            onChange={() => setIsColorChoice(!isColorChoice)}
          />
        }
        label={`Choix de la couleur par ${
          isColorChoice ? 'les joueurs' : "l'admin"
        }`}
      />
      <FormControlLabel
        className="setting"
        control={
          <Switch
            checked={seeBuzzList}
            color="primary"
            onChange={() => setSeeBuzzList(!seeBuzzList)}
          />
        }
        label={`Les joueurs ${
          seeBuzzList ? 'voient' : 'ne voient pas'
        } l'ordre des buzz`}
      />
      {seeBuzzList && (
        <FormControlLabel
          className="setting"
          control={
            <Switch
              checked={!hideScore}
              color="primary"
              onChange={() => setHideScore(!hideScore)}
            />
          }
          label={`Les joueurs ${
            !hideScore ? 'voient' : 'ne voient pas'
          } les scores des autres`}
        />
      )}
      <FormControlLabel
        className="setting"
        control={
          <Switch
            checked={onlyFirst}
            color="primary"
            onChange={() => setOnlyFirst(!onlyFirst)}
          />
        }
        label={`Mode only first (Seul le premier buzz sera retenu)`}
      />
      {!onlyFirst && (
        <TextField
          className="setting"
          label="Buzz possibles / joueur"
          type="number"
          value={nbBuzz}
          onChange={handleNbBuzz}
          onFocus={selectMe}
        />
      )}
      <TextField
        className="setting"
        label="Temps de cooldown pour mauvaise réponse"
        type="number"
        value={freezeTime}
        onChange={handleFreezeTime}
        onFocus={selectMe}
      />
      <div className="setting">
        <FormControl className="selectSound">
          <InputLabel>Son des buzzers</InputLabel>
          <Select value={buzzerSound} onChange={handleChangeSound}>
            {BUZZER_SOUNDS.map(sound => (
              <MenuItem key={sound.id} value={sound.id}>
                {sound.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={() => playSound(buzzerSound)}>
          <Play className="icon noSelect" />
        </IconButton>
      </div>
      <Accordion
        expanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="">Textes et couleurs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="configBuzzers">
            <FormControlLabel
              control={
                <Switch
                  checked={isCustom}
                  color="primary"
                  onChange={() => setIsCustom(!isCustom)}
                />
              }
              label={`Customisation ${isCustom ? 'détaillée' : 'globale'}`}
            />
            {isCustom ? renderCustomConf() : renderGlobalConf()}
          </div>
        </AccordionDetails>
      </Accordion>

      <div className="validateWrapper">
        <BuzzerButton
          content={<CheckIcon className="iconCheck" />}
          size={4}
          color="#4caf50"
          onBuzz={validate}
        />
      </div>
    </>
  );
};

export default Config;
