/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { DEFAULT_RING_COLOR } from '../../Constantes';

import './BuzzerButton.less';

let timer;

const BuzzerButton = ({
  content,
  onBuzz,
  color = '#EE0000',
  disabled = false,
  size = 10,
  delay = 140,
  ringColor = DEFAULT_RING_COLOR
}) => {
  // Clear le timeout à l'unmount
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  });

  // "Simplement" pour calculer un assombrissement d'une couleur
  const colorShade = (col, amt) => {
    col = col.replace(/^#/, '');
    if (col.length === 3)
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [
      parseInt(r, 16) + amt,
      parseInt(g, 16) + amt,
      parseInt(b, 16) + amt
    ];

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? '0' : '') + r;
    const gg = (g.length < 2 ? '0' : '') + g;
    const bb = (b.length < 2 ? '0' : '') + b;

    return `#${rr}${gg}${bb}`;
  };

  // Création d'un petit délais (140+1ms par défaut) pour voir
  // l'animation en entier sur les boutons qui change de page
  // Setté à 0+1ms pour le "vrai" buzzer
  const delayOnBuzz = () => {
    if (!disabled) {
      timer = setTimeout(() => {
        onBuzz();
      }, delay + 1);
    }
  };

  const bgColorShaded = colorShade(color, -100);
  return (
    <div className="buzzerWrapper noSelect">
      <div
        className="button noSelect"
        style={{ width: `${size}rem`, height: `${size}rem` }}
      >
        {/* Gère le style du bouton via les props */}
        <a
          onMouseUp={delayOnBuzz}
          className={disabled ? 'disabled' : undefined}
          style={{
            backgroundColor: color,
            boxShadow: `inset 0 1px 0 ${bgColorShaded}, 0 20px 0 ${bgColorShaded}`,
            width: `${size}rem`,
            height: `${size}rem`
          }}
        >
          {content}
        </a>
      </div>
      {/* Pas le truc le plus propre ! Mais permet de gérer les pseudo élément avec le js !
          .button:after => Ring
          .button a:active => "ombre" du bouton dont la couleur est calculée plus haut
                          le :not(.disabled) pour éviter "d'actionner" le bouton en disabled
      */}
      <style>
        {`
        .button:after{
          width: ${size + (8 * size) / 100 + 2 / size}rem;
          background-color: ${ringColor}; 
        }
        .button a:not(.disabled):active{ 
          box-shadow: inset 0 1px 0 ${bgColorShaded}, inset 0 -3px 0 ${bgColorShaded} !important;
        }
      `}
      </style>
    </div>
  );
};

export default BuzzerButton;
