import React, { useState } from 'react';
import { CirclePicker } from 'react-color';
import { ALL_COLORS } from '../../Constantes';

import './ColorPicker.less';

const ColorPicker = ({ color, setColor }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div
        className="boxColor noSelect"
        onClick={() => setIsVisible(!isVisible)}
        style={{ backgroundColor: color.hex }}
      />
      {isVisible && (
        <>
          <div className="cover" onClick={() => setIsVisible(false)} />
          <div className="popover">
            <CirclePicker
              color={color}
              onChange={newCol => setColor(newCol)}
              triangle="top-right"
              circleSize={50}
              onChangeComplete={() => setIsVisible(false)}
              colors={ALL_COLORS}
              width={192}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ColorPicker;
