import React from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const InputWithClear = ({
  id,
  label,
  value,
  setValue,
  clearValue,
  placeholder,
  className
}) => {
  const clear = clearValue
    ? clearValue
    : () => {
        setValue('');
      };
  const myId = id ? 'inputText-' + id : 'inputText-' + label;
  return (
    <FormControl className={className}>
      <InputLabel htmlFor={myId}>{label}</InputLabel>
      <Input
        id={myId}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        onChange={e => setValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={clear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default InputWithClear;
