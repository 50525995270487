import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  type: 'dark',
  primary: {
    main: '#83e2fc'
  },
  secondary: {
    main: '#3fb945'
  }
};
const themeName = 'Dove Gray';
export default createMuiTheme({ palette, themeName });
