import React, { useState } from 'react';

import './Admin.less';
import Config from './Config';
import Dashboard from './Dashboard';

const Admin = ({ socket, room, setRoom }) => {
  const [isConfig, setIsConfig] = useState(true);

  return (
    <div className="adminPanel custom-scrollbar">
      {isConfig ? (
        <Config socket={socket} room={room} setIsConfig={setIsConfig} />
      ) : (
        <Dashboard
          socket={socket}
          room={room}
          setIsConfig={setIsConfig}
          setRoom={setRoom}
        />
      )}
    </div>
  );
};

export default Admin;
